import { mapState, mapMutations } from 'vuex'

export default {
	methods: {
		...mapMutations('user', [
			'setActionUrl'
		]),
	},
	computed: {
		...mapState('user', ['actionUrl'])
	},
	watch: {
		actionUrl(v) {
			if (v) {
				this.getUrlParams()
				this.setActionUrl(false)
			}
		}
	}
}
